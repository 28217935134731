import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  
  public HOME_URL = environment.serverUrl;

  constructor() { }

  public MESSAGES: any = {
    'ERROR' : "Could not perform the request",
    'UNAUTHORIZED': "You're not authorized to access this page",
    'FORBIDDEN': "You don't have permission to access this page"
  }

  public RESPONSE_CODES: any = {
    'SUCCESS' : '200'
  }

  // Server Url
  public SERVER_URLS: any = {
    'IMAGE_URL': 'file/image/',
    'LOGIN' : 'user/login',
    'FORGOT_PASSWORD': 'user/forgot-password',
    'RESET_PASSWORD': 'user/reset-password',
    'ROLE': 'role',
    'ROLE_ACTIVE': 'role/active',
    'SLIDER':'slider/active',
    'BOARD_MEMBERS':'board-members/active',
    'ANNOUNCEMENTS_COUNTS': 'content/announcements-count',
    'IMPORTANT_lINKS_COUNTS': 'content/links-count',
    'USER_DROPDOWN': 'user/dropdown',
    'USER': 'user',
    'USER_PROFILE': 'user/profile',
    'USER_PROFILES': 'user/user-profile',
    'ADMIN_PROFILE':'user/admin/profile',
    'CHANGE_USER_STATUS': 'user/change-status',
    'CHANGE_PASSWORD': 'user/profile/change-password',
    'LATEST_NEWS_COUNTS': 'content/latest-news-count',
    'QUICK_LINK_COUNTS': 'content/quick-link-count',
    'CONTENT':'content/latest',
    'COUNTRY':'country',
    'COUNTRY_ACTIVE':'country/active',
    'STATE': 'state',
    'CITY': 'city/',
    'CITY_ACTIVE':'city/active',
    'ALLERGY':'allergy',
    'CUISINE':'cuisine',
    'WORK':'work',
    'WORK_ACTIVE':'work/active',
    'DAILY_ACTIVITY':'dailyactivity',
    'MEDICAL_CONDITION':'medical-condition',
    'EMOTIONAL_CONDITION':'emotional-condition',
    'REPORT_DOWNLOAD': 'report/download',
    'NOTIFICATION_MASTER': 'notification-master',
    'GOSHALA_REGISTER':'sanstha/registration',
    'DISTRICT_ACTIVE': 'district/active',
    'TALUKA_ACTIVE': 'taluka/active/',
    'NOTICE':'notice/active',
    'DONATION':'donation/donate',
    'GRIEVANCE_REGISTER':'grievance/add',
  }

  public VALIDATION_PATTERNS: any = {
    'EMAIL': '^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$',
    'MOBILE': '(^$|[0-9]{10})',
    'ZIP_CODE': '(^$|[0-9]{6})'
  }

  public getSessionStorage(): Storage | null {
    return typeof window !== 'undefined' ? window.sessionStorage : null;
  }

  public getAuthHeader(){
    const storage = this.getSessionStorage();
    if(storage && storage.getItem('currentUser')){
      let user = JSON.parse(storage.getItem('currentUser')!);
      return 'Bearer ' + (user && user.sessionToken ? user.sessionToken : '');
    }else{
      return '';
    }
  }


  public convertToBase64(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => result.next(reader.result!.toString());
    return result;
  }

  public hasPermission(...args: string[]){
    let hasPermission = false;
    if(sessionStorage.getItem('currentUser')){
      let currentUser = JSON.parse(sessionStorage.getItem('currentUser')!);
      if(args.indexOf(currentUser.role.name) > -1){
        hasPermission = true;
      }
    }
    return hasPermission;
  }

  public getImageUrl(imageName: string){
    return this.HOME_URL + this.SERVER_URLS['IMAGE_URL'] + imageName + '/';
  }

  public checkIfNumber(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public checkIfLetter(event: any): void {
    const pattern = /^[A-Za-z\s]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    const inputValue = event.target.value;
    if (inputChar === ' ' && inputValue.length === 0) {
      event.preventDefault();
      return;
    }
    if (inputChar === ' ' && inputValue[inputValue.length - 1] === ' ') {
      event.preventDefault();
      return;
    }
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  

  public checkIfMarathiLetter(event: any): void {
    const pattern = /^[a-zA-Z\s\u0900-\u097F]+$/;
  
    let inputChar = String.fromCharCode(event.charCode);
    const inputValue = event.target.value;
    if (inputChar === ' ' && inputValue.length === 0) {
      event.preventDefault();
      return;
    }
    if (inputChar === ' ' && inputValue[inputValue.length - 1] === ' ') {
      event.preventDefault();
      return;
    }
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  

  public checkIfAlphanumeric(event: any): void {
    const pattern = /^[A-Za-z0-9\s.,]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    const inputValue = event.target.value;
    if (inputChar === ' ' && inputValue.length === 0) {
      event.preventDefault();
      return;
    }
    if (inputChar === ' ' && inputValue[inputValue.length - 1] === ' ') {
      event.preventDefault();
      return;
    }
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  public validateString(event: any): void {
    const inputChar = String.fromCharCode(event.charCode);
    const inputValue = event.target.value;
  
    // Prevent space at the beginning
    if (inputChar === ' ' && inputValue.length === 0) {
      event.preventDefault();
      return;
    }
  
    // Prevent consecutive spaces
    if (inputChar === ' ' && inputValue[inputValue.length - 1] === ' ') {
      event.preventDefault();
      return;
    }
  
    // Allow any other characters (numbers, symbols, etc.)
    // No need to prevent anything else
  }
  
  

  public checkNoStartingOrConsecutiveSpaces(event: any): void {
    const inputChar = String.fromCharCode(event.charCode);
    const inputValue = event.target.value;
    if (inputChar === ' ' && inputValue.length === 0) {
      event.preventDefault();
      return;
    }
    if (inputChar === ' ' && inputValue[inputValue.length - 1] === ' ') {
      event.preventDefault();
      return;
    }
  }

  public checkBankAccountNumber(event: any): void {
    const inputValue = event.target.value; 
    const inputChar = event.data;           
  
    const minLength = 10; 
    const maxLength = 16;
  
    if (inputValue.length > maxLength) {
      event.preventDefault();
      return;
    }
    if (inputChar === ' ') {
      event.preventDefault();
      return;
    }
    if (!/^[0-9]*$/.test(inputChar)) {
      event.preventDefault();
      return;
    }
    if (inputValue.length < minLength) {
      // Handle feedback (e.g., show a message or alert) if necessary
    }
  }
  
  

  public checkNoStartingOrConsecutiveSpacesForAddress(event: any): void {
    const inputChar = String.fromCharCode(event.charCode);
    const inputValue = event.target.value;
    if (inputChar === ' ' && inputValue.length === 0) {
      event.preventDefault();
      return;
    }
    if (inputChar === ' ' && inputValue[inputValue.length - 1] === ' ') {
      event.preventDefault();
      return;
    }
}

public checkMaxLength(event: any): void {
  let input = event.target.value;
  input = input.replace(/[+-]/g, '');
  if (input.length > 2) {
    input = input.slice(0, 2);
  }
  event.target.value = input;
}

public dontAcceptSign(event: any): void {
  let input = event.target.value;
  input = input.replace(/[+-]/g, '');
  if (input.length > 0) {
    input = input.slice(0, 25);
  }
  event.target.value = input;
}

  
  
  public onlyNumber(event: any): void {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return;
    }
    if (inputChar === '+' || inputChar === '-' || inputChar === ' ' || inputChar === '.' || inputChar === ',') {
      event.preventDefault();
      return;
    }
  }

  /**
     * Success Message Method
     */
  public successMsg(message: string) {
    if (typeof document !== 'undefined') {
      // Hide error snackbar if it's open
      //var erEl = document.getElementById("error-snackbar");
      //erEl.className = erEl.className.replace("show", "");

      var x = document.getElementById("success-snackbar");
      x.innerHTML = '<i class="fa fa-check-circle" aria-hidden="true"></i>&nbsp;&nbsp;' + message;
      x.className = "show";
      setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
    } 
  }

  /**
   * Error Message Method
   */
  public errorMsg(message: string) {
    if (typeof document !== 'undefined') {
      var x = document.getElementById("error-snackbar");
      document.getElementById("error-msg-disp").innerHTML = message;
      x.className = "show";
      setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
    }
  }
}
